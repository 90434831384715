import "./App.css";
import "./Style.scss";
import React, { lazy, Suspense, useState } from "react";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { polygonAmoy } from "wagmi/chains";
import {
  metaMaskWallet,
  walletConnectWallet,
  injectedWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
const Banner = lazy(() => import("./Banner"));
const BuyNFT = lazy(() => import("./buyNFT"));
const ColumncontentSection = lazy(() => import("./columncontentSection"));
const ContentSection = lazy(() => import("./contentSection"));
const Header = lazy(() => import("./Header"));
const SliderSection = lazy(() => import("./Slider"));
const FAQ = lazy(() => import("./FAQ"));
const Footer = lazy(() => import("./Footer"));

const queryClient = new QueryClient();

const polygonAmoyWithRPC = {
  ...polygonAmoy,
  rpcUrls: {
    default: {
      http: [
        process.env.REACT_APP_RPC_URL_1,
        process.env.REACT_APP_RPC_URL_2,
        process.env.REACT_APP_RPC_URL_3,
      ],
    },
  },
};

export const config = getDefaultConfig({
  appName: "ByteBridge",
  projectId: process.env.REACT_APP_PROJECT_ID,
  chains: [polygonAmoyWithRPC],
  wallets: [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet, walletConnectWallet, injectedWallet],
    },
  ],

  ssr: false,
});

function App() {
  const [category, setCategory] = useState(0);
  const handleOnCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
  };
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <div className="App">
            <Suspense fallback={<div>Loading...</div>}>
              <Header />
              <Banner />
              <BuyNFT category={category} />
              <SliderSection handleOnCategorySelect={handleOnCategorySelect} />
              <ContentSection />
              <ColumncontentSection />
              <FAQ />
              <Footer />
            </Suspense>
          </div>
          <Toaster position="top-center" />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
